

































import type { PropType } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { LinkType } from '~/types/components/Link/LinkType';
import { isInspirationsBlogUrl } from '~/helpers/isInspirationsBlogUrl/isInspirationsBlogUrl';
import ExternalLink from '~/components/atoms/Link/ExternalLink/ExternalLink.vue';

export default defineComponent({
  name: 'Link',
  components: {
    ExternalLink
  },
  props: {
    text: {
      type: String as PropType<VueI18n.TranslateResult>,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: LinkType.Primary,
      validator(value: LinkType) {
        return value && Object.values(LinkType).includes(value);
      }
    },
    blank: {
      type: Boolean,
      default: false
    },
    isBlock: {
      type: Boolean,
      default: false
    },
    shouldPushUrl: {
      type: Boolean,
      default: true
    }
  },
  setup(props: { url: string; }) {
    const linkContainsExpondoDotCom = props.url.includes('expondo.com');
    const linkContainsExpondoDot = props.url.includes('expondo.');
    const linkContainsExpondoSalesforce = props.url.includes('expondogmbh.my.site.com');
    const linkContainsHttp = props.url.includes('http');
    const linkContainsInspirationsBlog = isInspirationsBlogUrl(props.url);

    const isInternalLink = computed(() => {
      if (linkContainsExpondoDotCom) {
        return false;
      } else if (linkContainsInspirationsBlog) {
        return false;
      } else if (linkContainsExpondoSalesforce) {
        return false;
      } else if (linkContainsExpondoDot) {
        return true;
      } else if (linkContainsHttp) {
        return false;
      }
      return true;
    });
    return {
      LinkType,
      isInternalLink
    };
  }
});
