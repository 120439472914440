
















































































import { defineComponent } from '@nuxtjs/composition-api';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import ModalHeading from '@/components/molecules/ModalHeading.vue';
import Link from '~/components/atoms/Link/Link.vue';
import ExtraGuaranteeSidebarProduct from
  '~/components/organisms/ExtraGuarantee/ExtraGuaranteeSidebarProduct/ExtraGuaranteeSidebarProduct.vue';
import {
  useExtraGuarantee,
  useCartExtended,
  useExtraGuaranteePrice,
  useCartSidebars
} from '~/composables';
import CartSidebarButtons from '~/components/molecules/CartSidebars/CartSidebarButtons/CartSidebarButtons.vue';

export default defineComponent({
  name: 'ExtraGuaranteeInner',
  components: {
    CartSidebarButtons,
    ImgView,
    ModalHeading,
    Link,
    ExtraGuaranteeSidebarProduct
  },
  setup(_props) {
    const { skuForExtraGuarantee, productNameForExtraGuarntee, productImageForExtraGuarntee } = useExtraGuarantee();
    const { extraGuaranteePriceFormatted } = useExtraGuaranteePrice();
    const { handleExtraGuaranteeLogic } = useCartSidebars();

    const {
      addExtraGuaranteeProduct
    } = useCartExtended();
    const parentSku = skuForExtraGuarantee.value;

    const handleAcceptExtraGuarantee = async () => {
      await addExtraGuaranteeProduct(parentSku);
      handleExtraGuaranteeLogic(parentSku);
    };

    return {
      handleAcceptExtraGuarantee,
      extraGuaranteePriceFormatted,
      productNameForExtraGuarntee,
      productImageForExtraGuarntee,
      parentSku,
      handleExtraGuaranteeLogic
    };
  }
});
