










import { defineComponent } from '@nuxtjs/composition-api';
import SideBar from '~/components/organisms/SideBar/SideBar.vue';
import { useUiState, useExtraGuarantee, useCartSidebars } from '~/composables';
import ExtraGuaranteeInner from '~/components/organisms/ExtraGuarantee/ExtraGuaranteeInner/ExtraGuaranteeInner.vue';

export default defineComponent({
  name: 'ExtraGuaranteeSidebar',
  components: {
    SideBar,
    ExtraGuaranteeInner
  },
  setup() {
    const { isExtraGuaranteeOpen, toggleExtraGuarantee } = useUiState();
    const { handleExtraGuaranteeLogic } = useCartSidebars();
    const { skuForExtraGuarantee } = useExtraGuarantee();

    const extraGuaranteeClickOutside = () => {
      const parentSku = skuForExtraGuarantee.value;
      handleExtraGuaranteeLogic(parentSku);
    };

    return {
      isExtraGuaranteeOpen,
      toggleExtraGuarantee,
      extraGuaranteeClickOutside
    };
  }
});
